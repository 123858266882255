<template>
  <div>
    <header id="menubar">
      <div class="top-nav section section--inverted">
        <div class="d-flex container align-items-center">
          <div class="d-flex mr-auto justify-content-start"></div>
          <div class="d-flex ml-auto justify-content-end">
            <ul class="top-nav-links">
              <li class="top-nav-links-item social-item">
                <a
                  href="https://www.facebook.com/MuscatMizziAdvocates"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="icon-facebook"
                >
                  <i class="icon-facebook"></i>
                </a>
              </li>
              <li class="divider">|</li>
              <li class="top-nav-links-item social-item">
                <a
                  href="https://twitter.com/muscatmizzi"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="icon-twitter"
                >
                  <i class="icon-twitter"></i>
                </a>
              </li>
              <li class="divider">|</li>
              <li class="top-nav-links-item social-item">
                <a
                  href="https://www.linkedin.com/company/muscat-mizzi-advocates"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="icon-linkedin-logo"
                >
                  <i class="icon-linkedin-logo"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="container desktop d-none d-md-block">
        <div class="row">
          <nav class="col-12 menu-content">
            <a target="_parent" href="https://www.muscatmizzi.com" class="logo">
              <img
                src="https://www.muscatmizzi.com/storage/media/JowmLiXIEZse38qGC6rLQEU6RiMQcGIrXYbnUiLo.png"
                alt="logo"
                title="Muscat Mizzi Advocates"
                width="180"
                height="64"
              />
            </a>
            <ul class="menuitems">
              <li class="active">
                <a target="_parent" href="https://www.muscatmizzi.com/" title="Home">
                  <span data-flip="Home" class="flip">Home</span>
                </a>
              </li>
              <li class="active">
                <a target="_parent" href="https://www.muscatmizzi.com/firm" title="The Firm">
                  <span data-flip="The Firm" class="flip">The Firm</span>
                </a>
              </li>
              <li class="active">
                <a href="https://www.muscatmizzi.com/practices" title="Practices">
                  <span data-flip="Practices" class="flip">Practices</span>
                </a>
              </li>
              <li class="active">
                <a target="_parent" href="https://www.muscatmizzi.com/insights" title="Insights">
                  <span data-flip="Insights" class="flip">Insights</span>
                </a>
              </li>
              <li class="active">
                <a target="_parent" href="https://www.muscatmizzi.com/contact" title="Contact">
                  <span data-flip="Contact" class="flip">Contact</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="container mobile d-block d-md-none">
        <div class="row">
          <div class="col-12 menu-content">
            <a href="https://www.muscatmizzi.com" class="logo" target="_parent">
              <img
                src="https://www.muscatmizzi.com/storage/media/JowmLiXIEZse38qGC6rLQEU6RiMQcGIrXYbnUiLo.png"
                alt="logo"
                title="Muscat Mizzi Advocates"
                width="120"
                height="40"
              />
            </a>
            <a
              role="button"
              @click="mobileMenuShown = !mobileMenuShown"
              aria-label="toggle-menu"
              class="toggle-menu"
            >
              <span class="icon-menu"></span> &nbsp;
            </a>
          </div>
        </div>
      </div>
      <div id="menu-hide" :class="`menu-overlay ${mobileMenuShown ? '' : 'menu-hide'}`">
        <div class="container visible-xs">
          <div class="row">
            <nav class="col-12 text-center">
              <br />
              <div class="overlay-section">
                <div class="overlay-section-heading">
                  <a href="https://www.muscatmizzi.com/" target="_parent" title="Home">Home</a>
                </div>
              </div>
              <div class="overlay-section">
                <div class="overlay-section-heading">
                  <a href="https://www.muscatmizzi.com/firm" target="_parent" title="The Firm"
                    >The Firm</a
                  >
                </div>
              </div>
              <div class="overlay-section">
                <div class="overlay-section-heading">
                  <a href="https://www.muscatmizzi.com/practices" target="_parent" title="Practices"
                    >Practices</a
                  >
                </div>
              </div>
              <div class="overlay-section">
                <div class="overlay-section-heading">
                  <a href="https://www.muscatmizzi.com/insights" target="_parent" title="Insights"
                    >Insights</a
                  >
                </div>
              </div>
              <div class="overlay-section">
                <div class="overlay-section-heading">
                  <a href="https://www.muscatmizzi.com/contact" target="_parent" title="Contact"
                    >Contact</a
                  >
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
    <b-container class="section section--padding no-underline">
      <b-row class="mt-5">
        <b-col>
          <h3>ARMS Overcharge Calculator</h3>
          <div class="mb-2">
            This tool compares your actual bill against what ARMS has charged you. If you consume
            more electricity in summer than you do in winter, you have likely been illegally
            overcharged.
          </div>
          <div>
            To use the tool you will need 1 full year of your bills between 2015 and
            {{ computedGetFullYear }}. You may have received them in the post or you can download
            them from the ARMS website
            <a
              class="text-primary font-italic"
              href="https://arms.com.mt/en/services/bills/my-bills"
              target="_blank"
              >here</a
            >.
          </div>
          <hr />
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <b-form-row>
            <h4>Dwelling Information</h4>
            <div class="mb-2">
              If you are leasing your property, you are likely paying a domestic rate. If the
              property is your primary residence you are likely paying a residential rate.
            </div>
          </b-form-row>
          <b-form-row style="flex-direction: column">
            <label>Dwelling type</label>
            <b-form-radio-group
              id="account-type-radios"
              v-model="accountTypeRadios.selected"
              :options="accountTypeRadios.options"
              button-variant="primary"
              size="md"
              name="radio-btn"
              buttons
              class="mb-4"
            ></b-form-radio-group>
            <b-form-group
              label="Number of people registered"
              class="my-1"
              v-if="accountTypeRadios.selected == 'residential'"
            >
              <b-form-input
                id="people-number"
                type="number"
                min="0"
                size="md"
                v-model="peopleNumber"
              ></b-form-input>
            </b-form-group>
          </b-form-row>
          <!-- <b-form-row style="flex-direction: column">
              <h5>Circuit type</h5>
              <b-form-radio-group
                id="meter-phase-radios"
                v-model="meterPhaseRadios.selected"
                :options="meterPhaseRadios.options"
                button-variant="primary"
                size="md"
                name="radio-btn"
                buttons
              ></b-form-radio-group>
            </b-form-row> -->
          <br />
          <b-form-row>
            <h4>First bill</h4>
            <div class="mb-2">
              To get accurate results, you will need a bill for a billing period starting in
              December or January between 2012 and 2021. You can download it from
              <a
                class="text-primary font-italic"
                href="https://arms.com.mt/en/services/bills/my-bills"
                target="_blank"
                >here</a
              >.
            </div>
          </b-form-row>
          <b-form-row>
            <b-col sm="12" class="pl-0">
              <label for="start-of-billing-period">Start of billing period</label>
            </b-col>
            <b-col sm="11" class="pl-0">
              <b-form-datepicker
                id="start-of-billing-period"
                v-model="form.firstBillFromDate"
                class="mb-2 py-1"
                :date-disabled-fn="dateDisabled"
              ></b-form-datepicker>
            </b-col>
            <b-col sm="1">
              <b-icon-info-circle
                class="cursor-pointer text-primary"
                @click="toggleImgModal('firstBillDate')"
              ></b-icon-info-circle>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col sm="12" class="pl-0">
              <label for="initial-meter-reading">Initial meter reading</label>
            </b-col>
            <b-col sm="11" class="pl-0">
              <b-form-group id="initial-meter-reading-group" label-for="initial-meter-reading">
                <b-form-input
                  type="number"
                  min="0"
                  size="md"
                  id="initial-meter-reading"
                  name="initial-meter-reading"
                  v-model="$v.form.firstBillMeterValue.$model"
                  :state="validateState('firstBillMeterValue')"
                  aria-describedby="initial-meter-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="initial-meter-feedback"
                  >This is a required field and must be greater than 0.</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>
            <b-col sm="1">
              <b-icon-info-circle
                class="cursor-pointer text-primary"
                @click="toggleImgModal('firstMeterValue')"
              ></b-icon-info-circle>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col sm="12" class="pl-0">
              <label for="firstBillCost">Value of electricity consumed in first bill</label>
            </b-col>
            <b-col sm="11" class="pl-0">
              <b-form-group id="firstBillCost-group" label-for="firstBillCost">
                <b-form-input
                  type="number"
                  min="0"
                  size="md"
                  id="firstBillCost"
                  name="firstBillCost"
                  v-model="$v.form.firstBillCost.$model"
                  :state="validateState('firstBillCost')"
                  aria-describedby="firstBillCost-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="firstBillCost-feedback"
                  >This is a required field and must be greater than 0.</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>
            <b-col sm="1">
              <b-icon-info-circle
                class="cursor-pointer text-primary"
                @click="toggleImgModal('billCost')"
              ></b-icon-info-circle>
            </b-col>
          </b-form-row>
          <br />

          <b-form-row>
            <h4>Last bill</h4>
            <div class="mb-2">
              To get accurate results, you will need the bill dated 12 months after the first bill
              input above. This should have a billing period ending in December or January. You can
              download it from
              <a
                class="text-primary font-italic"
                href="https://arms.com.mt/en/services/bills/my-bills"
                target="_blank"
                >here</a
              >.
            </div>
          </b-form-row>
          <b-form-row>
            <b-col sm="12" class="pl-0">
              <label for="end-of-billing-period">End of billing period</label>
            </b-col>
            <b-col sm="11" class="pl-0">
              <b-form-datepicker
                id="end-of-billing-period"
                v-model="form.lastBillToDate"
                class="mb-2 py-1"
                size="sm"
                :date-disabled-fn="dateDisabled"
              ></b-form-datepicker>
            </b-col>
            <b-col sm="1">
              <b-icon-info-circle
                class="cursor-pointer text-primary"
                @click="toggleImgModal('lastBillDate')"
              ></b-icon-info-circle>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col sm="12" class="pl-0">
              <label for="lastBillMeterValue">Final meter reading</label>
            </b-col>
            <b-col sm="11" class="pl-0">
              <b-form-group id="lastBillMeterValue-group" label-for="lastBillMeterValue">
                <b-form-input
                  type="number"
                  min="0"
                  size="md"
                  id="lastBillMeterValue"
                  name="lastBillMeterValue"
                  v-model="$v.form.lastBillMeterValue.$model"
                  :state="validateState('lastBillMeterValue')"
                  aria-describedby="lastBillMeterValue-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="lastBillMeterValue-feedback"
                  >This is a required field and must be greater than 0.</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>
            <b-col sm="1">
              <b-icon-info-circle
                class="cursor-pointer text-primary"
                @click="toggleImgModal('lastMeterValue')"
              ></b-icon-info-circle>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col sm="12" class="pl-0">
              <label for="lastBillCost-group">Value of electricity consumed in last bill</label>
            </b-col>
            <b-col sm="11" class="pl-0">
              <b-form-group id="lastBillCost-group" label-for="lastBillCost">
                <b-form-input
                  type="number"
                  min="0"
                  size="md"
                  id="lastBillCost"
                  name="lastBillCost"
                  v-model="$v.form.lastBillCost.$model"
                  :state="validateState('lastBillCost')"
                  aria-describedby="lastBillCost-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="lastBillCost-feedback"
                  >This is a required field and must be greater than 0.</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>
            <b-col sm="1">
              <b-icon-info-circle
                class="cursor-pointer text-primary"
                @click="toggleImgModal('billCost')"
              ></b-icon-info-circle>
            </b-col>
          </b-form-row>
          <br />

          <b-form-row>
            <h4>Remaining bills in year</h4>
            <div class="mb-2">
              You need bills in the same year between the first and the last bills input above. You
              can view them
              <a
                class="text-primary font-italic"
                href="https://arms.com.mt/en/services/bills/my-bills"
                target="_blank"
                >here</a
              >.
            </div>
          </b-form-row>
          <b-form-row>
            <b-col sm="12" class="pl-0">
              <label for="secondBillCost-group">Value of electricity consumed in 2nd bill</label>
            </b-col>
            <b-col sm="11" class="pl-0">
              <b-form-group id="secondBillCost-group" label-for="secondBillCost">
                <b-form-input
                  type="number"
                  min="0"
                  size="md"
                  id="secondBillCost"
                  name="secondBillCost"
                  v-model="$v.form.secondBillCost.$model"
                  :state="validateState('secondBillCost')"
                  aria-describedby="secondBillCost-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="secondBillCost-feedback"
                  >This is a required field and must be greater than 0.</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>
            <b-col sm="1">
              <b-icon-info-circle
                class="cursor-pointer text-primary"
                @click="toggleImgModal('billCost')"
              ></b-icon-info-circle>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col sm="12" class="pl-0">
              <label for="thirdBillCost-group">Value of electricity consumed in 3rd bill</label>
            </b-col>
            <b-col sm="11" class="pl-0">
              <b-form-group id="thirdBillCost-group" label-for="thirdBillCost">
                <b-form-input
                  type="number"
                  min="0"
                  size="md"
                  id="thirdBillCost"
                  name="thirdBillCost"
                  v-model="$v.form.thirdBillCost.$model"
                  :state="validateState('thirdBillCost')"
                  aria-describedby="thirdBillCost-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="thirdBillCost-feedback"
                  >This is a required field and must be greater than 0.</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>
            <b-col sm="1">
              <b-icon-info-circle
                class="cursor-pointer text-primary"
                @click="toggleImgModal('billCost')"
              ></b-icon-info-circle>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col sm="12" class="pl-0">
              <label for="start-of-billing-period">Value of electricity consumed in 4rd bill</label>
            </b-col>
            <b-col sm="11" class="pl-0">
              <b-form-group id="forthBillCost-group" label-for="forthBillCost">
                <b-form-input
                  type="number"
                  min="0"
                  size="md"
                  id="forthBillCost"
                  name="forthBillCost"
                  v-model="$v.form.forthBillCost.$model"
                  :state="validateState('forthBillCost')"
                  aria-describedby="forthBillCost-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="forthBillCost-feedback"
                  >This is a required field and must be greater than 0.</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>
            <b-col sm="1">
              <b-icon-info-circle
                class="cursor-pointer text-primary"
                @click="toggleImgModal('billCost')"
              ></b-icon-info-circle>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col sm="12" class="pl-0">
              <label for="fifthBillCost-group">Value of electricity consumed in 5rd bill</label>
            </b-col>
            <b-col sm="11" class="pl-0">
              <b-form-group id="fifthBillCost-group" label-for="fifthBillCost">
                <b-form-input
                  type="number"
                  min="0"
                  size="md"
                  id="fifthBillCost"
                  name="fifthBillCost"
                  v-model="$v.form.fifthBillCost.$model"
                  :state="validateState('fifthBillCost')"
                  aria-describedby="fifthBillCost-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="fifthBillCost-feedback"
                  >This is a required field and must be greater than 0.</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>
            <b-col sm="1">
              <b-icon-info-circle
                class="cursor-pointer text-primary"
                @click="toggleImgModal('billCost')"
              ></b-icon-info-circle>
            </b-col>
          </b-form-row>

          <number-input
            class="mb-5"
            label="Value of electricity consumed in 6th bill <div class='font-italic'>Leave this field blank if the 5th bill was the last bill within the specified date range.</div>"
            id="sixth-bill-cost"
            :billCost="form.sixthBillCost"
            @valueChanged="form.sixthBillCost = $event"
            @infoClick="toggleImgModal('billCost')"
          />
          <b-form-row>
            <b-button variant="primary" class="btn-std" @click="postCalculate()"
              >Calculate</b-button
            >
          </b-form-row>
        </b-col>
        <b-col sm="5" offset-sm="1" v-if="calculationSuccess">
          <b-form-row>
            <h4>Your Results</h4>
          </b-form-row>
          <b-form-row> <h5>You paid</h5> </b-form-row>
          <b-form-row>
            <h4 class="no-underline">{{ currentTotalString }}</h4>
          </b-form-row>

          <b-form-row>
            <h5>You should have paid</h5>
          </b-form-row>
          <b-form-row>
            <h4 class="no-underline font-weight-bold">
              {{ legalTotalString }}
            </h4>
          </b-form-row>

          <b-form-row>
            <h5 class="text-danger">Arms overcharged you approximately</h5>
          </b-form-row>
          <b-form-row>
            <h4 class="no-underline font-weight-bold text-danger">
              {{ armsDueString }}
            </h4>
          </b-form-row>
          <b-form-row v-if="daysBetweenToDates > 356 || daysBetweenToDates < 356">
            <b-alert show variant="warning" v-if="daysBetweenToDates !== 365">
              The date range you selected totaled {{ daysBetweenToDates }} days. Maltese law
              dictates that calculations be done on annual basis. Therefore the total of the bills
              you paid was adjusted to display how much you
              {{ daysBetweenToDates &lt; 365 ? 'would' : ''}} have paid for 365 days of electrical
              consumption.
            </b-alert>
          </b-form-row>
          <b-form-row>
            <!-- <b-button v-if="legalTotal" variant="primary"
                >Share your results</b-button
              > -->
            <!-- <h5>Share your results</h5> -->
          </b-form-row>
          <b-form-row>
            <!-- <b-button v-if="legalTotal" variant="primary"
                >Share your results</b-button
              > -->
            <!-- <ShareNetwork
                network="facebook"
                :url="`https://www.muscatmizzi.com/lab/arms/share?hash=${computedShareHash}`"
                class="btn btn-std facebook"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-facebook"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"
                  />
                </svg>
                Facebook
              </ShareNetwork> -->
            <!-- &nbsp; -->
            <!-- <ShareNetwork
                network="twitter"
                :url="`https://www.muscatmizzi.com/lab/arms/share?hash=${computedShareHash}`"
                :title="`ARMS Overcharged me ${armsDueString}! Muscat Mizzi Arms Overcharge Calculator`"
                class="btn btn-std twitter"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-twitter"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"
                  />
                </svg>
  
                Twitter
              </ShareNetwork> -->
          </b-form-row>
          <hr />
          <b-form-row>
            <h4 class="font-weight-bold">Want your money back?</h4>
            <div for="email-input" class="mb-4">
              We intend to take action. Join the cause by inputting your email and phone number
              below and we will be in touch.
            </div>
            <br />
            <b-label name="email-input" for="email-input" style="font-weight: 600">Email</b-label>
            <b-form-input
              name="email-input"
              id="email-input"
              type="email"
              size="md"
              v-model="email"
              placeholder="your@email.com"
            ></b-form-input>
          </b-form-row>
          <b-form-row>
            <b-label name="contactNumber-input" style="font-weight: 600" for="contactNumber-input">
              Phone Number
            </b-label>
            <b-form-input
              name="contactNumber-input"
              id="contactNumber-input"
              type="number"
              size="md"
              v-model="contactNumber"
              placeholder="9912 3456"
              class="mb-4"
            ></b-form-input>
          </b-form-row>
          <b-form-row>
            <b-button variant="primary" class="btn-std" @click="postEmail()">Submit</b-button>
            <p v-if="postResponse">{{ postResponse }}</p>
          </b-form-row>
        </b-col>
      </b-row>
    </b-container>
    <b-modal size="lg" :ref="imgModalRef" hide-footer title="Image instructions">
      <img :src="modalImgSrc" width="100%" />
    </b-modal>
  </div>
</template>
<style>
body {
}
body p,
body label,
legend {
  font-size: 16px;
}
h5 {
  font-size: 18px;
}
h4 {
  font-size: 22px;
}
.form-row {
  margin: 0 0 1rem 0;
}
body h4 {
  font-size: 32px;
}

body h3 {
  font-size: 36px;
}
body input {
  line-height: 3rem;
}
body .b-form-datepicker label {
  box-shadow: none !important;
}
label,
legend {
  font-weight: 600;
}
</style>

<script>
import differenceInDays from "date-fns/differenceInDays";

import parse from "date-fns/parse";

import { validationMixin } from "vuelidate";

import { required } from "vuelidate/lib/validators";

import NumberInput from "../components/NumberInput.vue";

const greaterThanZero = (value) => value > 0;

export default {
  components: {
    NumberInput,
  },
  mixins: [validationMixin],
  data() {
    return {
      mobileMenuShown: false,
      formErrors: {},
      images: {
        firstMeterValue: "/img/firstMeterValue.jfif",
        lastMeterValue: "/img/lastMeterValue.jpg",
        billCost: "/img/billCost.jpg",
        firstBillDate: "/img/firstBillDate.jpg",
        lastBillDate: "/img/lastBillDate.jpg",
      },
      imgModalRef: "img-modal",
      modalImgSrc: "",
      accountTypeRadios: {
        selected: "residential",
        options: [
          { text: "Residential", value: "residential" },
          { text: "Domestic", value: "domestic" },
          // { text: "Non Residential", value: "non-residential" },
        ],
      },
      peopleNumber: 1,
      meterPhaseRadios: {
        selected: "single",
        options: [
          { text: "Single", value: "single" },
          { text: "Three Phase", value: "three-phase" },
        ],
      },
      unitsRadios: {
        selected: "kwh",
        options: [{ text: "kWh", value: "kwh" }],
      },
      form: {
        firstBillFromDate: "2021-01-01",
        firstBillMeterValue: 0,
        firstBillCost: 0,
        lastBillToDate: "2022-01-01",
        lastBillMeterValue: 0,
        lastBillCost: 0,
        secondBillCost: 0,
        thirdBillCost: 0,
        forthBillCost: 0,
        fifthBillCost: 0,
        sixthBillCost: 0,
      },
      email: "",
      contactNumber: "",
      postResponse: undefined,
      legalTotal: NaN,
      calculationSuccess: false,
    };
  },
  validations: {
    form: {
      firstBillFromDate: {
        required,
      },
      firstBillMeterValue: {
        required,
        maxValue: greaterThanZero,
      },
      firstBillCost: {
        required,
        maxValue: greaterThanZero,
      },
      lastBillToDate: {
        required,
      },
      lastBillMeterValue: {
        required,
        maxValue: greaterThanZero,
      },
      lastBillCost: {
        required,
        maxValue: greaterThanZero,
      },
      secondBillCost: {
        required,
        maxValue: greaterThanZero,
      },
      thirdBillCost: {
        required,
        maxValue: greaterThanZero,
      },
      forthBillCost: {
        required,
        maxValue: greaterThanZero,
      },
      fifthBillCost: {
        required,
        maxValue: greaterThanZero,
      },
    },
  },
  metaInfo() {
    return {
      title: "Muscat Mizzi Advocates | ARMS Calculator",
      meta: [
        { name: "description", content: this.computedDescription },
        { property: "og:description", content: this.computedDescription },
      ],
    };
  },
  created() {
    window.addEventListener("scroll", this.handleSCroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleSCroll);
  },
  computed: {
    computedGetFullYear() {
      return new Date().getFullYear();
    },
    computedShareHash() {
      if (
        this.$v.form.$anyError ||
        (Number.isNaN(this.currentTotal) &&
          Number.isNaN(this.legalTotal) &&
          Number.isNaN(this.armsDue))
      )
        return "";
      const text = `${this.currentTotal.toFixed(2)},${this.legalTotal.toFixed(
        2
      )},${this.armsDue.toFixed(2)}`;
      return btoa(unescape(encodeURIComponent(text)));
    },
    daysBetweenToDates() {
      return differenceInDays(
        parse(this.form.lastBillToDate, "yyyy-MM-dd", new Date()),
        parse(this.form.firstBillFromDate, "yyyy-MM-dd", new Date())
      );
    },
    accountTypeRadiosSelected() {
      return this.accountTypeRadios.selected;
    },
    computedDescription() {
      if (this.currentTotalString === "-" && this.legalTotalString === "-") return "";
      return `I used ARMS overcharge calculator at www.muscatmizzi.com/lab/arms. In 1 year, I paid
          ${this.currentTotalString}
          ', but I should have paid
          ${this.legalTotalString}
          .`;
    },
    currentTotal() {
      const totalPrice =
        Number(this.form.firstBillCost) +
        Number(this.form.lastBillCost) +
        Number(this.form.secondBillCost) +
        Number(this.form.thirdBillCost) +
        Number(this.form.forthBillCost) +
        Number(this.form.fifthBillCost) +
        Number(this.form.sixthBillCost);

      if (Number.isNaN(totalPrice)) {
        return NaN;
      }

      return this.calculateYearValue(totalPrice);
    },
    totalBillsPeriod() {
      const periodInTime =
        new Date(this.form.lastBillToDate).getTime() -
        new Date(this.form.firstBillFromDate).getTime();

      // To calculate the no. of days between two dates
      return periodInTime / (1000 * 3600 * 24);
    },
    armsDue() {
      if (Number.isNaN(this.currentTotal) || Number.isNaN(this.legalTotal)) {
        return NaN;
      }

      return this.currentTotal - this.legalTotal;
    },
    currentTotalString() {
      return Number.isNaN(this.currentTotal) || this.currentTotal === 0
        ? "-"
        : `€${this.currentTotal.toFixed(2)}`;
    },
    legalTotalString() {
      return Number.isNaN(this.legalTotal) ? "-" : `€${this.legalTotal.toFixed(2)}`;
    },
    armsDueString() {
      return Number.isNaN(this.armsDue) ? "-" : `€${this.armsDue.toFixed(2)}`;
    },
  },
  watch: {
    accountTypeRadiosSelected(val) {
      if (val === "non-residential" && this.unitsRadios.options.length === 1) {
        this.unitsRadios.options.push({ text: "kVah", value: "kVah" });
      } else if (this.unitsRadios.options.length > 1) {
        this.unitsRadios.options.pop();
        this.unitsRadios.selected = "kwh";
      }
    },
  },
  methods: {
    handleSCroll() {
      const body = document.querySelector("body");
      if (window.scrollY > 1 && !body.className.includes("small-bar")) {
        body.classList.add("small-bar");
      } else if (window.scrollY < 1) {
        body.classList.remove("small-bar");
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    dateDisabled(ymd, date) {
      const year = date.getFullYear();
      return year < 2015 || year > new Date().getFullYear();
    },
    toggleImgModal(imgName) {
      // Pass the image name to retrieve from 'images'
      this.modalImgSrc = this.images[imgName];
      this.$refs[this.imgModalRef].toggle();
    },
    onCalculationDone(data) {
      this.billResults.push(data);
    },
    calculateYearValue(totalPrice) {
      return (totalPrice / this.totalBillsPeriod) * 365;
    },
    postCalculate() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        setTimeout(() => {
          document.querySelectorAll(".is-invalid")[0].closest(".form-group").scrollIntoView({
            behavior: "smooth",
          });
        }, 100);
        return;
      }

      const data = {
        accountType: this.accountTypeRadiosSelected,
        peopleNumber: Number(this.peopleNumber),
        meterPhase: this.meterPhaseRadios.selected,
        firstBillStartDate: this.form.firstBillFromDate,
        firstBillStartMeter: Number(this.form.firstBillMeterValue),
        lastBillEndDate: this.form.lastBillToDate,
        lastBillEndMeter: Number(this.form.lastBillMeterValue),
      };

      fetch("https://arms-bills-calculator.pages.dev/api/calculate-bill", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((jsonResponse) => {
          const total = jsonResponse.data;
          this.calculationSuccess = true;
          this.legalTotal = this.calculateYearValue(total.consumptionTotal);

          document.body.scrollIntoView({ behavior: "smooth", block: "start" });
        })
        .catch(() => {
          this.legalTotal = "Error";
        });
    },
    postEmail() {
      if (!this.email) {
        return;
      }
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: this.email,
          contactNumber: this.contactNumber,
          currentTotal: this.currentTotal.toFixed(2),
          legalTotal: this.legalTotal.toFixed(2),
          armsDue: this.armsDue.toFixed(2),
        }),
      };
      fetch("https://arms-bills-calculator.pages.dev/api/email", requestOptions)
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          const alertForm = true;
          this.showAlert(alertForm);
        })
        .catch((e) => {
          console.error(e);
          const alertForm = false;
          this.showAlert(alertForm);
        });
    },
    showAlert(x) {
      if (x === false) {
        this.$swal({
          icon: "error",
          text: "There was an error while submitting your information. Kindly check your internet connection and try again.",
          showConfirmButton: false,
          timer: 3000,
        });
      } else if (x === true) {
        this.$swal({
          icon: "success",
          title:
            "Your details have been submitted successfully. Someone from our team will be in touch.",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
  },
};
</script>

<style>
@font-face {
  font-family: Gotham;
  src: url("../assets/fonts/Gotham-Light.woff") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: Gotham;
  src: url("../assets/fonts/Gotham-Bold.woff") format("woff2");
  font-weight: 700;
}
body {
  font-family: Gotham;
  font-size: 16px;
  color: #333;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 700 !important;
  color: #274b7b;
}
h4 {
  border-bottom: 2px solid #139cdc;
  font-weight: 700;
}
.no-underline {
  border-bottom: none;
}
#app {
  font-family: Gotham;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}
p {
  font-weight: normal;
}
body .form-control {
  display: block;
  width: 100%;
  height: 40px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 200;
  color: #555;
  background-image: none;
  border: 1px solid #acacac;
  border-radius: 0;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
body button,
body .btn {
  border-radius: 0;
  background: #fff;
  border: 1px solid #274b7b;
  padding: 0 10px;
  line-height: 36px;
  height: 36px;
  color: #274b7b;
  font-size: 13px;
  font-weight: lighter;
  transition-duration: 0.1s;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
.btn.btn-std {
  border-radius: 0;
  background: #274b7b;
  border: 1px solid #274b7b;
  padding: 0 10px;
  line-height: 36px;
  height: 36px;
  color: #fff;
  font-size: 13px;
  font-weight: lighter;
  transition-duration: 0.1s;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
.btn.twitter {
  background-color: #1da1f2;
  border: none;
}
.btn.facebook {
  background-color: #4267b2;
  border: none;
}
body .btn:hover {
  background: #139cdc !important;
  border-color: #139cdc;
  color: #fff !important;
}
body .btn-primary:not(:disabled):not(.disabled):active,
body .btn-primary:not(:disabled):not(.disabled).active,
body .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #274b7b;
  border-color: #274b7b;
}

#menubar,
#menubar .mobile {
  z-index: 10;
}
#menubar {
  top: 0;
  width: 100%;
  background: #fff;
  position: fixed;
  border-bottom: 2px solid #139cdc;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#menubar .desktop .logo {
  position: absolute;
  display: block;
  font-size: 0;
  line-height: 0;
  height: 60px;
  left: 20px;
  top: 50%;
  transition-property: all;
  transition-duration: 0.4s;
  transform-origin: 0 0;
  transform: scale(1) translateY(-50%);
}
#menubar .desktop .logo img {
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
#menubar .desktop ul.menuitems {
  margin: -19px 0 0;
  padding: 0;
  height: 36px;
  line-height: 36px;
  float: right;
  top: 50%;
  transition-duration: 1s;
  list-style: none;
  overflow: hidden;
}
#menubar .desktop ul.menuitems li {
  display: inline-block;
  margin: 0 20px;
  transition-duration: 0.25s;
  height: 100%;
  font-size: 1.3rem;
}
.section.section--inverted {
  background: #274b7b;
  color: #fff;
}
.top-nav > div .top-nav-links {
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}
.top-nav > div .top-nav-links li {
  display: inline-block;
  color: #fff;
}
.top-nav > div .top-nav-links .divider {
  color: #fff;
  margin: 0 0.2rem;
}
#menubar {
  top: 0;

  width: 100%;

  z-index: 10;

  background: #fff;

  position: fixed;

  border-bottom: 2px solid #139cdc;

  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
}

#menubar .mobile {
  z-index: 10;
}

#menubar .mobile .menu-content {
  height: 50px;

  -webkit-transition-duration: 300ms;

  transition-duration: 300ms;
}

#menubar .mobile .logo {
  position: absolute;

  display: block;

  font-size: 0;

  line-height: 0;

  height: 40px;

  left: 20px;

  top: 50%;

  -webkit-transform: translateY(-50%);

  transform: translateY(-50%);
}

#menubar .mobile .logo img {
  height: 100%;

  object-fit: contain;
}

#menubar .mobile .toggle-menu {
  height: 100%;

  position: absolute;

  top: 0;

  right: 0;

  padding: 0 15px 0 40px;

  font-size: 22px !important;

  line-height: 50px !important;

  color: #333;
}

#menubar .mobile .toggle-menu .icon-menu {
  top: 4px;
}

.small-bar #menubar .desktop .menu-content {
  height: 50px;
}

.small-bar #menubar .desktop .logo {
  -webkit-transform: scale(0.6) translateY(-50%);

  transform: scale(0.6) translateY(-50%);
}

.small-bar #menubar .menu-overlay {
  top: 52px;
}

#menubar .desktop {
  overflow: hidden;

  z-index: 10;
}

#menubar .desktop .menu-content {
  height: 100px;

  -webkit-transition-duration: 300ms;

  transition-duration: 300ms;
}

#menubar .desktop .logo {
  position: absolute;

  display: block;

  font-size: 0;

  line-height: 0;

  height: 60px;

  left: 20px;

  top: 50%;

  -webkit-transition-property: all;

  transition-property: all;

  -webkit-transition-duration: 400ms;

  transition-duration: 400ms;

  -webkit-transform-origin: 0 0%;

  transform-origin: 0 0%;

  -webkit-transform: scale(1) translateY(-50%);

  transform: scale(1) translateY(-50%);
}

#menubar .desktop .logo img {
  height: 100%;

  object-fit: contain;
}

#menubar .desktop ul.menuitems {
  margin: -19px 0 0 0;

  padding: 0;

  height: 36px;

  line-height: 36px;

  float: right;

  top: 50%;

  -webkit-transition-duration: 1s;

  transition-duration: 1s;

  list-style: none;

  overflow: hidden;
}

#menubar .desktop ul.menuitems li {
  display: inline-block;

  margin: 0 20px;

  -webkit-transition-duration: 250ms;

  transition-duration: 250ms;

  height: 100%;

  font-size: 1.3rem;
}

#menubar .desktop ul.menuitems li.separator {
  color: #333;
}

#menubar .desktop ul.menuitems li.search {
  width: 36px;

  height: 36px;

  margin: 0;

  display: inline-block;

  font-size: 0;
}

#menubar .desktop ul.menuitems li.search .searchButton {
  text-align: center;

  margin: 0;

  width: 100%;

  height: 36px;

  -webkit-transition-property: background;

  transition-property: background;

  -webkit-transition-duration: 200ms;

  transition-duration: 200ms;

  -webkit-transition-delay: 400ms;

  transition-delay: 400ms;

  z-index: 10;
}

#menubar .desktop ul.menuitems li.search .searchButton .icon-magnifier {
  -webkit-transition-property: color;

  transition-property: color;

  -webkit-transition-duration: 200ms;

  transition-duration: 200ms;

  -webkit-transition-delay: 400ms;

  transition-delay: 400ms;

  color: #274b7b;

  top: 5px;

  font-size: 18px;

  margin-bottom: -10px;
}

#menubar .desktop ul.menuitems li.search form {
  position: absolute;

  pointer-events: none;

  right: 100%;

  top: 0;

  overflow: hidden;

  height: 36px;

  z-index: 5;
}

#menubar .desktop ul.menuitems li.search form input {
  width: 390px;

  height: 36px;

  box-sizing: border-box !important;

  border: 1px solid #139cdc;

  border-right-width: 0;

  padding: 2px 10px 0 10px;

  opacity: 0;

  -webkit-transition-property: opacity, -webkit-transform;

  transition-property: opacity, -webkit-transform;

  transition-property: transform, opacity;

  transition-property: transform, opacity, -webkit-transform;

  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

  -webkit-transition-duration: 400ms !important;

  transition-duration: 400ms !important;

  -webkit-transform: translateX(390px);

  transform: translateX(390px);
}

#menubar .desktop ul.menuitems li a {
  display: inline-block;

  font-weight: normal;

  line-height: 30px;

  color: #274b7b !important;

  padding: 0;

  margin: 0;

  height: 30px;
}

#menubar .desktop ul.menuitems li a:hover .flip {
  -webkit-transform: translateY(-100%);

  transform: translateY(-100%);
}

#menubar .desktop ul.menuitems li a .flip {
  display: block;

  -webkit-transition-duration: 300ms;

  transition-duration: 300ms;

  -webkit-transition-property: opacity, -webkit-transform;

  transition-property: opacity, -webkit-transform;

  transition-property: transform, opacity;

  transition-property: transform, opacity, -webkit-transform;

  -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);

  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

#menubar .desktop ul.menuitems li a .flip::after {
  color: #139cdc;

  position: absolute;

  top: 100%;

  left: 0;

  content: attr(data-flip);
}

#menubar .desktop ul.menuitems li a::after,
#menubar .desktop ul.menuitems li a::before {
  content: " ";

  font-size: 0;

  height: 2px;

  position: absolute;

  bottom: -0px;

  left: -3px;

  right: -3px;

  background: #139cdc;

  opacity: 0;

  -webkit-transition-property: opacity, -webkit-transform;

  transition-property: opacity, -webkit-transform;

  transition-property: transform, opacity;

  transition-property: transform, opacity, -webkit-transform;

  -webkit-transition-duration: 0s, 150ms;

  transition-duration: 0s, 150ms;

  -webkit-transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);

  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);

  -webkit-transform-origin: 0 0;

  transform-origin: 0 0;

  -webkit-transform: scaleX(0);

  transform: scaleX(0);
}

#menubar .desktop ul.menuitems li a::after {
  -webkit-transition-duration: 100ms, 150ms;

  transition-duration: 100ms, 150ms;
}

#menubar .desktop ul.menuitems li a.underlined::before,
#menubar .desktop ul.menuitems li a.underlined-router::before {
  opacity: 1;

  -webkit-transform: scaleX(1);

  transform: scaleX(1);
}

#menubar .desktop ul.menuitems li a.charge-underline:hover::before {
  opacity: 0;
}

#menubar .desktop ul.menuitems li a.charge-underline:hover::after {
  opacity: 1;

  -webkit-transition-duration: 2500ms, 150ms;

  transition-duration: 2500ms, 150ms;

  -webkit-transform: scaleX(1);

  transform: scaleX(1);
}

#menubar .desktop ul.menuitems.search-visible li:not(.search) {
  opacity: 0;

  pointer-events: none;
}

#menubar .desktop ul.menuitems.search-visible .search .searchButton {
  -webkit-transition-delay: 0s;

  transition-delay: 0s;

  background: #274b7b;
}

#menubar .desktop ul.menuitems.search-visible .search .searchButton .icon-magnifier {
  color: #fff;

  -webkit-transition-delay: 0s;

  transition-delay: 0s;
}

#menubar .desktop ul.menuitems.search-visible .search form {
  pointer-events: auto;
}

#menubar .desktop ul.menuitems.search-visible .search form input {
  opacity: 1;

  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

  -webkit-transform: translateX(0px);

  transform: translateX(0px);
}

#menubar .menu-overlay {
  position: absolute;

  width: 100%;

  right: 0;

  top: 102px;

  height: calc(100vh - 50px - 2px);

  background: rgba(255, 255, 255, 0.95);

  -webkit-transition-property: opacity, top, -webkit-transform;

  transition-property: opacity, top, -webkit-transform;

  transition-property: transform, opacity, top;

  transition-property: transform, opacity, top, -webkit-transform;

  -webkit-transition-duration: 300ms;

  transition-duration: 300ms;

  -webkit-transform: scale(1);

  transform: scale(1);

  padding-top: 20px;

  z-index: 5;

  overflow-y: auto;
}

@media (max-width: 767px) {
  #menubar .menu-overlay {
    top: 52px !important;
  }
}

#menubar .menu-overlay.ng-hide {
  -webkit-transform: scale(0.9);

  transform: scale(0.9);
}

#menubar .menu-overlay .close-overlay:hover {
  color: #274b7b;
}

#menubar .menu-overlay .logo {
  display: block;

  margin: 80px auto 0 auto;

  width: 62px;
}

@media (max-width: 767px) {
  #menubar .menu-overlay .logo {
    margin-top: 40px;

    width: 50px;
  }
}

#menubar .menu-overlay .overlay-section.overlay-section--active .overlay-section-heading::before,
#menubar .menu-overlay .overlay-section.overlay-section--active .overlay-section-heading::after {
  width: calc(50% - 9px);
}

#menubar .menu-overlay .overlay-section.overlay-section--active .overlay-section-heading .triangle {
  display: block;
}

#menubar .menu-overlay .overlay-section.overlay-section--active .overlay-section-content {
  display: block;
}

#menubar .menu-overlay .overlay-section .overlay-section-heading {
  line-height: 54px;

  text-align: center;
}

#menubar .menu-overlay .overlay-section .overlay-section-heading a {
  font-size: 18px;

  font-weight: normal;
}

#menubar .menu-overlay .overlay-section .overlay-section-heading .triangle {
  display: none;

  position: absolute;

  bottom: -7px;

  left: 50%;

  margin-left: -7px;

  width: 14px;

  height: 14px;

  -webkit-transform: rotate(45deg);

  transform: rotate(45deg);

  border-right: 1px solid #acacac;

  border-bottom: 1px solid #acacac;
}

#menubar .menu-overlay .overlay-section .overlay-section-heading::before,
#menubar .menu-overlay .overlay-section .overlay-section-heading::after {
  position: absolute;

  content: " ";

  font-size: 0;

  width: 50%;

  bottom: 0;

  height: 1px;

  background: #acacac;
}

#menubar .menu-overlay .overlay-section .overlay-section-heading::before {
  left: 0;
}

#menubar .menu-overlay .overlay-section .overlay-section-heading::after {
  right: 0;
}

#menubar .menu-overlay .overlay-section .overlay-section-content {
  padding: 30px 0 0 0;

  border-bottom: 1px solid #acacac;

  display: none;
}

#menubar .menu-overlay .overlay-section .overlay-section-content .overlay-navlist {
  display: inline-block;

  margin-bottom: 30px;

  width: 20%;

  float: left;
}

@media (max-width: 991px) {
  #menubar .menu-overlay .overlay-section .overlay-section-content .overlay-navlist {
    width: 33.33333%;
  }
}

#menubar .menu-overlay .overlay-section .overlay-section-content .overlay-navlist a.category {
  font-size: 18px;

  color: #139cdc;
}

#menubar .menu-overlay .overlay-section .overlay-section-content .overlay-navlist a {
  display: block;

  color: #333;
}

#menubar .menu-overlay .overlay-section .overlay-section-content .overlay-navlist a:hover {
  color: #274b7b;
}
</style>
