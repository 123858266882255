<template>
  <b-form-row>
    <b-col sm="12" class="pl-0">
      <label v-html="label" :for="id"></label>
    </b-col>
    <b-col sm="11" class="pl-0">
      <b-form-input
        :name="id"
        :id="id"
        type="number"
        min="0"
        size="md"
        :value="billCost"
        @keyup="$emit('valueChanged', +$event.target.value)"
      ></b-form-input>
    </b-col>
    <b-col sm="1">
      <b-icon-info-circle
        @click="$emit('infoClick')"
        class="cursor-pointer text-primary"
      ></b-icon-info-circle>
    </b-col>
  </b-form-row>
</template>

<script>
import { BIconInfoCircle } from "bootstrap-vue";

export default {
  props: ["label", "id", "billCost"],
  components: {
    BIconInfoCircle,
  },
};
</script>

<style></style>
